import {appAdapter} from './../app/appadaper'
import { hexMD5 } from '../request/md5';

String.prototype.insert = function (index, string) {
    if (index > 0)
      return this.substring(0, index) + string + this.substring(index, this.length);
    else
      return string + this;
  };

export const DEVICEPLATFORM_IOS          = 1
export const DEVICEPLATFORM_ANDROID      = 2
  
export default class CPCONFIG {

    isWx() {
        if (window.wx ===undefined) {
            return false;
        }
        return true;
    }

    isH5() {
        return window.wx ===undefined;
    }

    getPageContentHeight() {
        let height = this.getWindowHeight() - this.getTabBarHeight() - this.getTopHeight();
        return height;
    }

    getHomeSearchBarHeight() {
        return 46;
    }

    getHomePageContentHeight() {

        let topBarHeight = 64; // 固定的 
        let height = this.getWindowHeight() - this.getTabBarHeight() - this.getTopHeight() - topBarHeight - this.getHomeSearchBarHeight() - this.getBottomHeight();
        return height;
    }

    // ios 1  android 2 

    getPlatform() {
        let u = navigator.userAgent;
        let isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 是否为ios设备
        if (isIos) {
            return DEVICEPLATFORM_IOS;
        }
        else {
            return DEVICEPLATFORM_ANDROID;
        }

    }

    getTabBarHeight() {

        let tabItem = document.querySelector('.at-tab-bar at-tab-bar--fixed');
        if (tabItem) {
            return tabItem.style.height * 1;
        }

        return 58;
    }
    
    getTopBarHeight() {

        let topBar = document.querySelector('.taro-scroll taro-scroll-view__scroll-x');
        if (topBar) {
            return topBar.style.height * 1;
        }
        return 44;
    }

    getSysInfo() {

        let sysInfo = window.wx.getSystemInfoSync();

        return sysInfo;
    }

    // 获取部的高度，为了适配小程序
    getTopHeight() {

        let height = 0;
        if (!this.isWx()) {
           return height;       
        }
        let sysInfo = window.wx.getSystemInfoSync();
        if (sysInfo != null)
        {
            height = sysInfo.statusBarHeight;
            return height;
        }
        return height;
    }

    getPlayControlerHeight() {
        return 14;
    }

    // 获取底部的高度
    getBottomHeight() {
        if (!this.isWx()) {
            return 0;
        }

        let safeArea =  window.wx.getSystemInfoSync().safeArea2;
        if (safeArea ===undefined) {
            return 0;
        }

        return safeArea.bottom;
    }

    getWindowHeight() {

        let width = window.screen.availWidth;
        let height = window.screen.availHeight;
 
        if (width > height) {
            return width;
        }
        return height;
    }
    getWindowWidth() {

        let width = window.screen.availWidth;
        let height = window.screen.availHeight;
        if (width > height) {
            return height;
        }
        return width;
    }

    getNavigatorBarHeight() {

        return 44;
    }

    getSystem() {
        if (!this.isWx()) {
            return 'ios';
        }

        let system =  window.wx.getSystemInfoSync().system;
        if (system ===undefined) {
            return 'ios';
        }

        return system;
    }

    getVersion() {
        if (!this.isWx()) {
            return '9.0';
        }

        let version =  window.wx.getSystemInfoSync().version;
        if (version ===undefined) {
            return '9.0';
        }

        return version;
    }

    getSDKVersion() {
        if (!this.isWx()) {
            return '1.0';
        }

        let version =  window.wx.getSystemInfoSync().SDKVersion;
        if (version ===undefined) {
            return '1.0';
        }

        return version;
    }


    getHomeContentHeight() {
        return this.getWindowHeight() - this.getTopHeight() 
        - this.getTabBarHeight() - this.getTopBarHeight() - this.getBottomHeight();
    }


    isNeedSdkPlayer() {

        return false;
        // if (this.isH5()) {
        //     return false;
        // }

        // return this.isSupportSDKPlayer();
    }
    
    // 后续去掉这个方法
    isSupportSDKPlayer() {

        // if (this.isH5()) {
        //     return false;
        // }
        return false;

        // let sdkVersion = this.getSDKVersion().replace('.','').replace('.','').replace('.','');
        // if (sdkVersion != undefined) {
         
        //     let version1 = 0;
        //     if (sdkVersion.length ==2) {
        //         version1 = parseInt(sdkVersion);
        //         if (version1 >29) {
        //             return true;
        //         }
        //     }

        //     if (sdkVersion.length ==3) {
        //         version1 = parseInt(sdkVersion);
        //         if (version1 > 290) {
        //             return true;
        //         }
        //     }

        //     if (sdkVersion ==4) {
        //         version1 = parseInt(sdkVersion);
        //         if (version1 > 2900) {
        //             return true;
        //         }
        //     }
        // }

        // return false;
    }


    isSupportLiving() {

        if (!this.isWx()) {
            return false;
        }

        let sdkVersion = this.getSDKVersion().replace('.','').replace('.','');
        if (sdkVersion ==='28') {
            
            return false;
        }

        if (sdkVersion ==='29') {
            return true;
        }

        if (sdkVersion > 272) {
            return true;
        }
        else {
           return false;
        }
    }


    getPageHeight() {
        let statusBarHeight = this.getTopHeight();
        let screenHeight = window.screen.availHeight;
        let tabBarHeight = this.getTabBarHeight();
        let topBarHeight = this.getTopBarHeight();
        let pageHeight = screenHeight - statusBarHeight - tabBarHeight - topBarHeight;
        return pageHeight;
    }

    getEquiCompany() {
        return 'apple';
    }
    
    getTerimal() {
        return '1';
    }

    getRelease() {
        return '311';
    }

    // 这个调用的时候取 
    getIdfa() {
        let seq ='';
        if (!this.isWx()) {
            seq='8f966d17ae1749699c124e1f1ca89662C30mC30mCJ8sC3G'
            return seq;
        }

        
        seq = appAdapter().getStore().getSeq();
        if (seq !== undefined && seq.length > 0 && seq !== '84a559f573636a47ff8d1CJanCZ8sC3KmDZKrC30mCW') {
            return seq;
        }


        let info = window.wx.getSystemInfoSync()
        if ((info.idfa && info.idfa.indexOf("0000-0000")===-1)) {
            info.uuid = info.idfa
        } else if (info.udid2) {
            info.uuid = info.udid2
        } else {
            info.uuid = info.udid
        }

        let md5 = hexMD5(info.uuid)
        let result = md5.insert(21, this.getTerimal());
        result = result.substring(1, result.length);
        console.log('😄😄=====seq' + result);
        return result;
    }

    getSystemInfo() {
        if (this.isWx()) {
            return window.wx.getSystemInfoSync();
        }
        return null;
        
    }

    getSystemVersion() {
        if (this.isWx()) {
            return window.wx.getSystemInfoSync().version;
        } else {
            if(this.getPlatform() ===1){
                var str= navigator.userAgent.toLowerCase(); 
                var ver=str.match(/cpu iphone os (.*?) like mac os/);
                let os = ver[1].replace(/_/g,".")
                return os
            }
        }
        return 9.0;
    }


    // 这个是激活接口的时候 
    getIdfaV2() {
        let seq = "";
        seq = '45424d78e2cd6d92d86c1d0b7c63e8a6';
        if (!this.isWx()) {
          return seq;
        }

        let info = window.wx.getSystemInfoSync()
        if ((info.idfa && info.idfa.indexOf("0000-0000")===-1)) {
            info.uuid = info.idfa
        } else if (info.udid2) {
            info.uuid = info.udid2
        } else {
            info.uuid = info.udid
        }
       
        
        let md5 = hexMD5(info.uuid)
        let result = md5.insert(21, this.getTerimal());
        result = result.substring(1, result.length);
        console.log('😄😄=====seq' + result);
        return result;
    }
    

    getAuth() {
        let auth = appAdapter().getStore().getAuthorization()
        return auth;
    }
}