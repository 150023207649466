
export class CountryCodeDB{
  getCountryCodeDB(){
    const ccDB = {
      HOT: [
        ["中国大陆", "86"],
        ["香港", "852"],
        ["澳门", "853"],
        ["台湾", "886"],
        ["柬埔寨", "855"]
      ],
      A: [
        ["阿尔巴尼亚", "355"],
        ["阿尔及利亚", "213"],
        ["阿富汗", "93"],
        ["阿根廷", "54"],
        ["阿鲁巴岛", "297"],
        ["阿曼", "968"],
        ["阿塞拜疆", "994"],
        ["阿森松(英)", "247"],
        ["埃及", "20"],
        ["埃塞俄比亚", "251"],
        ["爱尔兰", "353"],
        ["爱沙尼亚", "372"],
        ["安道尔", "376"],
        ["安哥拉", "244"],
        ["安圭拉岛(英)", "1264"],
        ["安提瓜和巴布达", "1268"],
        ["奥地利", "43"],
        ["澳大利亚", "61"],
        ["澳门", "853"]
      ],
      B: [
        ["巴巴多斯", "1246"],
        ["巴布亚新几内亚", "675"],
        ["巴哈马国", "1242"],
        ["巴基斯坦", "92"],
        ["巴拉圭", "595"],
        ["巴林", "973"],
        ["巴拿马", "507"],
        ["巴西", "55"],
        ["白俄罗斯", "375"],
        ["百慕大群岛(英)", "1441"],
        ["保加利亚", "359"],
        ["贝宁", "229"],
        ["比利时", "32"],
        ["冰岛", "354"],
        ["波多黎各(美)", "1"],
        ["波兰", "48"],
        ["波斯尼亚和黑塞哥维那", "387"],
        ["玻利维亚", "591"],
        ["伯利兹", "501"],
        ["博茨瓦纳", "267"],
        ["不丹", "975"],
        ["布基纳法索", "226"],
        ["布隆迪", "257"]
      ],
      C: [["朝鲜", "850"], ["赤道几内亚", "240"]],
      D: [
        ["丹麦", "45"],
        ["德国", "49"],
        ["东萨摩亚(美)", "1684"],
        ["多哥", "228"],
        ["多米尼加共和国", "1809"],
        ["多米尼克国", "1767"]
      ],
      E: [["俄罗斯", "7"], ["厄瓜多尔", "593"], ["厄立特里亚", "291"]],
      F: [
        ["法国", "33"],
        ["法罗群岛(丹)", "298"],
        ["法属波里尼西亚", "689"],
        ["梵蒂冈", "14397"],
        ["菲律宾", "63"],
        ["斐济", "679"],
        ["芬兰", "358"],
        ["佛得角", "238"],
        ["福克兰群岛", "500"]
      ],
      G: [
        ["冈比亚", "220"],
        ["刚果", "242"],
        ["哥伦比亚", "57"],
        ["哥斯达黎加", "506"],
        ["格林纳达", "1473"],
        ["格陵兰岛", "299"],
        ["格鲁吉亚", "995"],
        ["古巴", "53"],
        ["瓜德罗普岛(法)", "590"],
        ["关岛(美)", "1671"],
        ["圭亚那", "592"]
      ],
      H: [
        ["哈萨克斯坦", "7"],
        ["海地", "509"],
        ["韩国", "82"],
        ["荷兰", "31"],
        ["荷属安的列斯群岛", "599"],
        ["洪都拉斯", "504"],
        ["基里巴斯", "686"]
      ],
      J: [
        ["吉布提", "253"],
        ["吉尔吉斯斯坦", "996"],
        ["几内亚", "224"],
        ["几内亚比绍", "245"],
        ["加拿大", "1"],
        ["加纳", "233"],
        ["加蓬", "241"],
        ["柬埔寨", "855"],
        ["捷克", "420"],
        ["津巴布韦", "263"]
      ],
      K: [
        ["喀麦隆", "237"],
        ["卡塔尔", "974"],
        ["开曼群岛(英)", "1345"],
        ["科科斯岛", "61"],
        ["科克群岛(新)", "682"],
        ["科摩罗", "269"],
        ["科特迪瓦", "225"],
        ["科威特", "965"],
        ["克罗地亚", "385"],
        ["肯尼亚", "254"]
      ],
      L: [
        ["拉脱维亚", "371"],
        ["莱索托", "266"],
        ["老挝", "856"],
        ["黎巴嫩", "961"],
        ["立陶宛", "370"],
        ["利比里亚", "231"],
        ["利比亚", "218"],
        ["列支敦士登", "423"],
        ["留尼汪岛", "262"],
        ["卢森堡", "352"],
        ["卢旺达", "250"],
        ["罗马尼亚", "40"]
      ],
      M: [
        ["马达加斯加", "261"],
        ["马尔代夫", "960"],
        ["马耳他", "356"],
        ["马拉维", "265"],
        ["马来西亚", "60"],
        ["马里", "223"],
        ["马里亚纳群岛", "1670"],
        ["马其顿", "389"],
        ["马绍尔群岛", "692"],
        ["马提尼克(法)", "596"],
        ["马约特岛", "262"],
        ["毛里求斯", "230"],
        ["毛里塔尼亚", "222"],
        ["美国", "1"],
        ["蒙古", "976"],
        ["蒙特塞拉特岛(英)", "1664"],
        ["孟加拉国", "880"],
        ["秘鲁", "51"],
        ["密克罗尼西亚(美)", "691"],
        ["缅甸", "95"],
        ["摩尔多瓦", "373"],
        ["摩洛哥", "212"],
        ["摩纳哥", "377"],
        ["莫桑比克", "258"],
        ["墨西哥", "52"]
      ],
      N: [
        ["纳米比亚", "264"],
        ["南非", "27"],
        ["南斯拉夫", "381"],
        ["瑙鲁", "674"],
        ["尼泊尔", "977"],
        ["尼加拉瓜", "505"],
        ["尼日尔", "227"],
        ["尼日利亚", "234"],
        ["纽埃岛(新)", "683"],
        ["挪威", "47"],
        ["诺福克岛(澳)", "672"]
      ],
      P: [["帕劳(美)", "680"], ["葡萄牙", "351"]],
      R: [["日本", "81"], ["瑞典", "46"], ["瑞士", "41"]],
      S: [
        ["萨尔瓦多", "503"],
        ["塞拉利昂", "232"],
        ["塞内加尔", "221"],
        ["塞浦路斯", "357"],
        ["塞舌尔", "248"],
        ["桑给巴尔", "259"],
        ["沙特阿拉伯", "966"],
        ["圣诞岛", "61"],
        ["圣多美和普林西比", "239"],
        ["圣赫勒拿", "290"],
        ["圣克里斯托弗和尼维斯", "1869"],
        ["圣卢西亚", "1758"],
        ["圣马力诺", "378"],
        ["圣皮埃尔岛及密克隆岛", "508"],
        ["圣文森特岛(英)", "1784"],
        ["斯里兰卡", "94"],
        ["斯洛伐克", "421"],
        ["斯洛文尼亚", "386"],
        ["斯威士兰", "268"],
        ["苏丹", "249"],
        ["苏里南", "597"],
        ["所罗门群岛", "677"],
        ["索马里", "252"]
      ],
      T: [
        ["塔吉克斯坦", "992"],
        ["台湾", "886"],
        ["泰国", "66"],
        ["坦桑尼亚", "255"],
        ["汤加", "676"],
        ["特克斯和凯科斯群岛(英)", "1649"],
        ["特立尼达和多巴哥", "1868"],
        ["突尼斯", "216"],
        ["图瓦卢", "688"],
        ["土耳其", "90"],
        ["土库曼斯坦", "993"]
      ],
      W: [
        ["瓦努阿图", "678"],
        ["危地马拉", "502"],
        ["维尔京群岛(英)", "1340"],
        ["委内瑞拉", "58"],
        ["乌干达", "256"],
        ["乌克兰", "380"],
        ["乌拉圭", "598"],
        ["乌兹别克斯坦", "998"]
      ],
      X: [
        ["西班牙", "34"],
        ["西萨摩亚", "685"],
        ["希腊", "30"],
        ["香港", "852"],
        ["新加坡", "65"],
        ["新喀里多尼亚群岛(法)", "687"],
        ["新西兰", "64"],
        ["匈牙利", "36"],
        ["叙利亚", "963"]
      ],
      Y: [
        ["牙买加", "1876"],
        ["亚美尼亚", "374"],
        ["也门", "967"],
        ["伊拉克", "964"],
        ["伊郎", "98"],
        ["以色列", "972"],
        ["意大利", "39"],
        ["印度", "91"],
        ["印度尼西亚", "62"],
        ["英国", "44"],
        ["约旦", "962"],
        ["越南", "84"]
      ],
      Z: [
        ["赞比亚", "260"],
        ["乍得", "235"],
        ["直布罗陀(英)", "350"],
        ["智利", "56"],
        ["中非", "236"]
      ]
    };
    return ccDB;
  }
}
