/* eslint-disable no-undef */
import CPStorage from './appcallstorage'

export default class CPAppAdapter {


    constructor () {
        this.store = new CPStorage();
    }
    getStore() {
      return this.store;
    }
}

export function appAdapter() {

  let adapter = window.appAdapter;
  if (adapter === undefined) {
    adapter = new CPAppAdapter()
  }
  return adapter;
}