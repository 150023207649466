import logo from '../../asset/image/logo@2x.png';
import pclogo from '../../asset/image/pop_logo@2x.png';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu, Breadcrumb, Avatar, Row, Col } from 'antd';
import { UserOutlined, VideoCameraAddOutlined, UploadOutlined, MailOutlined } from '@ant-design/icons';
import './homepage.scss'
import { appAdapter } from '../../app/appadaper'
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

class homepage extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            userName: '',
            userPhone: '',
            activekey: []
        }
    }
    componentDidMount() {
        let wapp_user = appAdapter().getStore().getWappUser()
      
        if (wapp_user) {
            console.log(wapp_user.name)
            let userName = wapp_user.aliasName || wapp_user.name || wapp_user.phone
            let userPhone = wapp_user.phone
            this.setState({
                userName: userName,
                userPhone: userPhone
            })
        }
        this.initActive()
    }
    componentWillUnmount() {
        
    }
    initActive(){
        if (window.location.href.indexOf('uploadvideo') > 0) {
            this.setState({
                activekey: [].concat(['1']),
            })
        }
        if (window.location.href.indexOf('UploadList') > 0) {
            this.setState({
                activekey: [].concat(['2']),
            })
        }
    }
    componentWillReceiveProps(nextProps) {
        this.initActive()
        console.log(nextProps)
        console.log(this.props)
        // if (nextProps.location.hash !== this.props.location.hash) {
        //     window.leftMenu.forceUpdate(); //当路由不同时，强制更新左边栏组件
        // }
    }
    logout() {
        appAdapter().getStore().removeWappUser()
    }
    render() {
        let { userName, userPhone } = this.state
        let clientHeight= document.documentElement.clientHeight - 88;
        return (
            <Layout className="homepage">
                <Header className="header">
                    <Row>
                        <Col flex="100px" className="head-logo">
                            <img src={pclogo}></img>
                        </Col>
                        <Col flex="auto" className="head-fight">
                            <span className="h-name">{userPhone ? '用户:' + userPhone : '绑定手机号才能上传哦!'}</span>
                            <span className="loginout" onClick={this.logout.bind(this)}>
                                <Link to={{ pathname: '/Login' }} className="home-link">
                                    退出
                                </Link>
                            </span>
                        </Col>
                    </Row>
                </Header>
                <Layout>
                    <Sider width={200} className="site-layout-background">
                        <div className="header-box">
                            <Avatar className="heading" size={64} src={logo} icon={<UserOutlined />} />
                            <div className="headname">
                                {userName ? userName : 'A昵称'}
                            </div>
                        </div>
                        <Menu
                            mode="inline"
                            defaultSelectedKeys={this.state.activekey}
                            // defaultOpenKeys={['sub1']}
                            // selectedKeys={this.state.activekey}
                            style={{ height: '100%', borderRight: 0 }}
                            className="sider-left"
                        >
                            <Menu.Item key="1" icon={<VideoCameraAddOutlined />}>
                                <Link to={{ pathname: '/dragUpload' }} className="home-link-item">
                                    发布视频
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="2" icon={<UploadOutlined />}>
                                <Link to={{ pathname: '/UploadList' }} className="home-link-item">
                                    内容管理
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </Sider>
                    <Layout style={{ padding: '24px 24px 0px' }}>

                        <Content style={{ height: `${clientHeight}px`, overflow: 'scroll' }}>
                            <div
                                className="" style={{
                                    padding: 24,
                                    margin: 0,
                                    background: '#fff',
                                    minHeight: '100%',
                                }}>
                                {this.props.children}
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        );
    }
}
export default homepage