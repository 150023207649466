import {getData, postData} from './../../app/appdomain'

export default class CPUpload {

  constructor() {
    this.currentUploadIndex = 0;

  }

    queryUploadList(obj) {
        return new Promise(function(resolve, reject) {
            getData("/movUser/queryMyUpAllList", obj, false)
                .then(res => {
                    if (resolve) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    if (reject) {
                        reject(err);
                    }
              });
        });
    }

    delUpMov(id) {
      return new Promise(function(resolve, reject) {
        postData("/movUser/delUpMov", {id: id}, false)
          .then(res => {
            if (resolve) {
              resolve(res.data);
            }
          })
          .catch(err => {
            if (reject) {
              reject(err);
            }
          });
      });
    }

    // 获取上传需要的参数 
    getUploadParams() {
      return new Promise(function(resolve, reject) {
        getData('/sts/getToken', {},false)
          .then(res => {
            if (resolve) {
              resolve(res.data);
            }
          })
          .catch(err => {
            if (reject) {
              reject(err);
            }
          });
      });
    }

    checkFile(hash) {
      return new Promise(function(resolve, reject) {
        getData('/movUser/hashExist', {hash : hash},false)
          .then(res => {
            if (resolve) {
              resolve(res.data);
            }
          })
          .catch(err => {
            if (reject) {
              reject(err);
            }
          });
      });
    }

   ///movUser/hashExist



    // 
    // 
    // movieObj = {
    //   movName : 标题,
    //   hash : 视频hash,
    //   actor : 主演,
    //   movDesc : 视频描述,
    //   movCls : 分类ID,
    //   tagids : 标签ID ',' 分割,
    //   localId : 地区ID，
    //   mins ： 视频时长,
    //   suffix : 视频后缀,
    //   shard : 视频地址,
    //   resourceType : 视频类型,
    //   movPackageId : 视频套餐ID，
    //   topicId ： 话题ID，

    // }
    publishMovie(movieObj) {

      return new Promise(function(resolve, reject) {
        postData('/movUser/saveMov', movieObj,false)
          .then(res => {
            if (resolve) {
              resolve(res.data);
            }
          })
          .catch(err => {
            if (reject) {
              reject(err);
            }
          });
      });

    }

    // /qrCode/checkLogin
}





