import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';

import Root from './router/Routes';

const mountNode = document.getElementById('root');

// ReactDOM.render(
//   <BrowserRouter>
//     <Root />
//   </BrowserRouter>,
//   mountNode
// )
ReactDOM.render(<Root />, document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
