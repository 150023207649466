import {getData, postData} from './../../app/appdomain'

// 用于应用程序启动的处理
export default class CPAppManager {

        
    getParams() {
        return new Promise(function(resolve, reject) {
          getData('/param/list', {},false)
            .then(res => {
              if (resolve) {
                resolve(res.data);
              }
            })
            .catch(err => {
              if (reject) {
                reject(err);
              }
            });
        });
      }

}