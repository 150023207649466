

// 用于生成随机数
export default function makeRandom(min, max) {
    var range = max - min;
    var rand = Math.random();
    return min + Math.round(rand * range);
}


export function copy(mainObj) {
    let objCopy = {}; // objCopy 将存储 mainObj 的副本
    let key;
   
    for (key in mainObj) {
      objCopy[key] = mainObj[key]; // 将每个属性复制到objCopy对象
    }
    return objCopy;
  }

