import React from 'react';
import {HashRouter, Route, Switch, Redirect } from 'react-router-dom';


import App from './../App';
import Homepage from './../page/home/homepage';
import UploadList from './../page/UploadList';
import Login from './../page/Login';
import UploadVideo from './../page/UploadVideo';
import DragUpload from './../page/UploadVideo/dragUpload';
const Root = () => (
   <HashRouter>
   <Switch>
      <Route
         path="/"
         render={props => (
            <App>
               <Switch>
                  <Route path="/Login" component={Login} />
                  <Route
                     path="/"
                     render={prop => (
                        <Homepage>
                           <Switch>
                              <Route path="/" exact component={UploadList} />
                              <Route path="/dragUpload" component={DragUpload} />
                              <Route path="/uploadvideo" component={UploadVideo} />
                              {/* <Route path="/UploadList" component={UploadList} /> */}
                              <Route render={() => <Redirect to="/" />} />
                           </Switch>
                        </Homepage>
                     )}
                  />
                  {/*路由不正确时，默认跳回home页面*/}
                  <Route render={() => <Redirect to="/" />} />

               </Switch>
            </App>
         )}
      />
   </Switch>
   </HashRouter>
);

export default Root