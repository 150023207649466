// import logo from './logo.svg';
// import React, { Component } from 'react'
// import './App.css';
// // import React from 'react';
// // import ReactDOM from 'react-dom';
// import { Button } from 'element-react';
// import 'element-theme-default';
import { getDomain, getBucket} from './app/appdomain'
import CPAppManager from './Datas/app/index'
import {GetUploadManager} from './Datas/Uplode/upload'

// export default class App extends Component {

//   componentDidMount(){
//     getDomain().then(res=>{
//       // console.log('getDomain返回结果==='+JSON.stringify(res))
//     }).catch(err=>{
//       console.log('err...'+err);
//     })

//   }
//   render() {
//       return (
//           <div className="M-App">
//              <Button type="primary">Hello</Button>
//           </div>
//       )
//   }
// }
// <div>
// jjjjjj
// </div>
// <Link to={{ pathname: '/test1' }} className="home-link">
// 111
// </Link>
// <Link to={{ pathname: '/test2' }} className="home-link">
// 222
// </Link>

import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
class App extends Component {

    constructor() {
        super(...arguments);
        this.app = new CPAppManager();



        GetUploadManager().loadUploadingList();
        GetUploadManager().resetUplod();
    }

    componentDidMount(){
        getDomain().then(res=>{
            
        }).catch(err=>{
        console.log('err...', err);
        })

        this.app.getParams().then(res=>{
            console.log('获取用户的参数为',res);
            window.endpoint   = res.data[0].endpoint;  
            window.bucketName = res.data[0].bucketName;
        }).catch(err=>{

        });
        getBucket().then(res=>{
            
        }).catch(err=>{
        console.log('getBucket err...', err);
        })

    }
    render() {
        return (<div>
            {this.props.children}
        </div>);
    }
}
export default App