import React from 'react';
import { Form, Input, Button, Select, Checkbox, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import CPUserInfo from "../../Datas/User/index";
import {appAdapter} from '../../app/appadaper'
import QRCode from 'qrcode.react';
import bg from '../../asset/image/bg@2x.png'
import pop_logo from '../../asset/image/pop_logo@2x.png'
import saalogo from '../../asset/image/saalogo@2x.png'
import QRlogin from '../../asset/image/QRlogin@2x.png'
import passwordlogin from '../../asset/image/passwordlogin@2x.png'
import {CountryCodeDB} from './CountryCodeDB';
import { postData } from '../../app/appdomain';
import {setStorageSync, getStorageSync} from '../../app/appstorage'

import './index.scss'
const { Option } = Select;
export default class Login extends React.Component {
    constructor() {
        super(...arguments);
        let prefixSelector = (
            <Form.Item name="prefix" noStyle>
                <Select style={{ width: 70 }}>
                    <Option value="86">+86</Option>
                </Select>
            </Form.Item>
        );
        this.state = {
            QRCodeUrl: '',
            QRExpired: false,
            loginType: false,
            TabLogin: 1,
            countryList: [],
            prefixSelector: prefixSelector
        }

        this.user = new CPUserInfo();
        this.onFinish = values => {
            console.log('Received values of form: ', values);

            let obj = {
                name: values.username,
                password: values.password,
            }
            this.userLogin(obj)
        };
        this.onFinishOphone = values => {
            console.log('Received values of form: ', values);

            if (values.prefix == undefined) {
                values.prefix = '+86';
            }
            let obj = {
                name: values.phone,
                password: values.password,
                cntryCode: values.prefix
            }
            this.userLogin(obj)
        }
        this.prefixSelector = prefixSelector
        let countryList = this.getCountry()
        // console.log('++++++countryList', countryList)
        this.renderPrefixSelector =  (
            <Form.Item name="prefix" noStyle>
                <Select size="large" style={{ width: 130 }} defaultValue={ countryList[0].code }>
                    {
                        countryList.map((item, i) =>{
                        return (<Option maxTagTextLength='20' key={i} value={item.code}>{item.name}</Option>)
                        })
                    }
                </Select>
            </Form.Item>
        );
    }
    // 
    componentDidMount() {
        let wapp_user = appAdapter().getStore().getWappUser()
        if(wapp_user && wapp_user.myInviteCode){
             this.props.history.push({ pathname: '/UploadList', state: { name: 'sunny' } })
        } else {
            // this.getVisitorToken()
        }
    }
    /**
     * 构建初始化国家码数据
     */
    getCountry() {
        //Taro国家码数组
        let countryList = [];
        let countryCodeDB = new CountryCodeDB();
        //国家吗数据源
        let ccDB = countryCodeDB.getCountryCodeDB();
        //拿到json对象所有key
        let countryCodeKeys = Object.keys(ccDB);
        let items = [];
        countryCodeKeys.map((item,index)=>{
            ccDB[item].map((cc,i)=>{
                let countryAndCode = {};
                countryAndCode['name'] = cc[0] + ' +' + cc[1];
                countryAndCode['country']= cc[0];
                countryAndCode['code']= '+' + cc[1];
                items.push(countryAndCode)
            });
        })
        return items
    }

    getVisitorToken(){
        this.user.getVisitorToken().then( res => {
            if(res.code === 0){
                console.log(res.data[0].qrCode)
                let QRCodeUrl = res.data[0].qrCode
                this.setState({
                    QRCodeUrl: QRCodeUrl
                })
                this.adTimer = setInterval(() => {
                    this.getQrCodeCheckLogin(QRCodeUrl)
                }, 1000);
                setTimeout(() => {
                    clearInterval(this.adTimer); // 定时器
                    this.adTimer = undefined;
                    this.setState({
                        QRExpired: true
                    })
                }, 30000);
            }
        }).catch(err => {
            console.log(err)
        })
    }
    getQrCodeCheckLogin(QRCodeUrl) {
        this.user.getQrCodeCheckLogin({ qrCode: QRCodeUrl}).then( res => {
            if(res.data.data && res.data.data !== undefined && res.data.data.length > 0){
                let auth = res.headers.authorization;
                if (auth == undefined || auth.length <=0) {
                    auth = res.headers.Authorization;
                }
        
                if (auth != undefined && auth.length >0) {
        
                    let testId = getStorageSync('testId');
                    if (testId != undefined  && testId.toString() == 'xxzdebug')  {
                    alert('auth====='+getStorageSync('weapp_authorization'));
                    }
        
                    auth = getStorageSync('weapp_authorization');
                
                    if (auth == undefined || auth.length <=0) {
                    setStorageSync('weapp_authorization',auth);
                    }
                    this.user.userLogin()
                    setTimeout(() => {
                        console.log(123)
                        this.props.history.push({ pathname: '/', state: { name: 'sunny' } })
                    }, 500);
                }
                appAdapter().getStore().storeWappUser(res.data[0])
                clearInterval(this.adTimer); // 定时器
                this.adTimer = undefined
                
            }
        }).catch(err => {
            console.log(err)
        })
    }

    refreshQR() {
        this.getVisitorToken()
        this.setState({
            QRExpired: false
        })
    }

    onLoginType() { // 登录类型 false：账号  true：二维码
        let {loginType} = this.state
        if(!loginType) {
            this.getVisitorToken()
        } else {
            clearInterval(this.adTimer); // 定时器
            this.adTimer = undefined;
        }
        loginType = loginType? false : true
        this.setState({
            loginType: loginType
        })
    }
    onTabLogin(index) { // 账户 ｜｜ 手机号
        this.setState({
            TabLogin: index
        })
        // if(index == 1){
        //     this.getCountry()
        // }
    }
    userLogin(obj) {
        let {TabLogin} = this.state
        postData('/user/login', obj).then(res => {
            if (res.data.success) {
                console.log('res.123132123231', res)
                console.log('res.headers.authorization;', res.headers.authorization)
                let auth = res.headers.authorization;
                if (auth == undefined || auth.length <=0) {
                    auth = res.headers.Authorization;
                }
        
                if (auth != undefined && auth.length >0) {
        
                    let testId = getStorageSync('testId');
                    if (testId != undefined  && testId.toString() == 'xxzdebug')  {
                    alert('auth====='+getStorageSync('weapp_authorization'));
                    }
        
                    auth = getStorageSync('weapp_authorization');
                
                    if (auth == undefined || auth.length <=0) {
                    setStorageSync('weapp_authorization',auth);
                    }
                    this.user.userLogin()
                    setTimeout(() => {
                        this.props.history.push({ pathname: '/', state: { name: 'sunny' } })
                    }, 500);
                }
            } else {
                message.error(res.data.msg);
            }
        }).catch(err => {
            console.log('/user/login', err);
        })
    }

    render() {
        let {QRCodeUrl, QRExpired, loginType, prefixSelector, TabLogin} = this.state
        return (
            <div className="login">
                <div className="login-img">
                    <img alt="" src={bg}></img>
                </div>
                <div className="login-saalogo">
                    <img alt="" src={saalogo}></img>
                </div>
                {
                    loginType?<div className="loginQRCode-view">
                    <img alt="" src={pop_logo} className="loginQRCode-logo"></img>
                    <img alt="" src={passwordlogin} onClick={this.onLoginType.bind(this)} className="loginType"></img>
                    
                    <QRCode
                        value={QRCodeUrl}// 生成二维码的内容
                        size={213} // 二维码的大小
                        fgColor="#000000" // 二维码的颜色
                        className="loginQRCode-QC"
                        // imageSettings={{ // 中间有图片logo
                        //     src: logo,
                        //     height: 60,
                        //     width: 60,
                        //     excavate: true
                        // }}
                    />
                    {QRExpired&& <div className="QRExpired" onClick={this.refreshQR.bind(this)}>
                        <div className="QRExpired-text">
                            二维码已失效
                        </div>
                        <div className="QRExpired-Btn">
                            点击刷新
                        </div>
                    </div>}
                    <p className="openTheHG">打开黄瓜视频APP</p>
                    <p className="ScanQRCode">扫描二维码登录</p>
                </div>:
                <div className="login-view">
                    <img alt="" src={pop_logo} className="login-view-logo"></img>
                    
                    <img alt="" src={QRlogin} onClick={this.onLoginType.bind(this)} className="loginType"></img>
                    <div className="tabLogin">
                        <div className="tabLogin_phone" onClick={this.onTabLogin.bind(this, 0)}>
                            帐户密码登录
                            {TabLogin==0?<span className="tabLoginSel"></span>:''}
                        </div>
                        <div className="tabLogin_name" onClick={this.onTabLogin.bind(this, 1)}>
                            手机号登录
                            {TabLogin==1?<span className="tabLoginSel"></span>:''}
                        </div>
                    </div>
                    {TabLogin==0?
                        <div className='Login_name'>
                            <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{ remember: true }}
                            onFinish={this.onFinish}
                            >
                                <Form.Item
                                    name="username"
                                    rules={[{ required: true, message: '请输入用户名!' }]}
                                >
                                    <Input size="large" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入用户名" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: '请输入6-12位密码!' }]}
                                >
                                    <Input
                                    size="large" 
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="请输入6-12位密码"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" size="large" htmlType="submit" className="login-form-button">
                                    登录
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>:
                        <div className='Login_phone'>
                            <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{ remember: true }}
                            onFinish={this.onFinishOphone}
                            >
                               
                               <Form.Item
                                    name="phone"
                                    rules={[{ required: true, message: '请输入您的手机号!' }]}
                                >
                                    <Input  
                                    size="large"
                                    addonBefore={this.renderPrefixSelector} 
                                    style={{ width: '100%' }} 
                                    placeholder="请输入您的手机号"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: '请输入6-12位密码!' }]}
                                >
                                    <Input
                                    size="large" 
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="请输入6-12位密码"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" size="large" htmlType="submit" className="login-form-button">
                                    登录
                                    </Button>
                                </Form.Item>
                            </Form>

                        </div>}
                    
                </div>} 
            </div>
        )
    }
}

