import AWS from 'aws-sdk'
import {
  hexMD5
} from './../../request/md5';

import streamToBlob from 'stream-to-blob'
import  SparkMD5, { hash } from 'spark-md5'
import {appAdapter} from '../../app/appadaper'


import CPUpload from './index'

  //  window.uploads 保存上传的对象
  // uploadState 0 表示没有上传 1 表示上传中 3 表示上传失败 4 表示上传成功 5 停止状态 
  // uploadProcess 上传进度
  // uploadData 上传后的数据
  // uploadMeta 上传的元数据 
  // uid 唯一标识 
  // title 视频标题 
export default class CPUploadManager {

  constructor() {
    this.currentUploadIndex = 0;

    // 表示当前的上传管理器的状态 
    this.currentUploadState = 0; //表示没有上传 1 表示上传中 3 表示上传失败 4 表示上传成功 5 停止状态 

    // 上传的对象
    this.upload = new CPUpload();

    // 上传的回调
    this.uploadCallbck = undefined;

    // 保存当前正在上传的 上传对象 
    this.currentSelectUploadObject = undefined;

    // 正在上传的对象
    this.currentUploadObject = undefined;

    // 记录本地的上传对象
    this.localUploadList = [];

    // 启动一个定时器来处理上
    this.loadUploadingList();

    // 保存本地上传请求对象
    this.uploadRequests = {};

    setInterval(() => {
      this.uploadFile();
    }, 5000);
  }

 // 读取本地缓存的上传对象，并把所有的本地没有上传或上传没有成功设置为上传失败 
  loadUploadingList() {

    console.log('加载本地信息');

    let _localUploadList = appAdapter().getStore().getLocalUploadList();
    if (_localUploadList == undefined) {
      this.localUploadList = [];
    }
    else {
      this.localUploadList = _localUploadList;
    }

    let newUploadList = [];
    this.localUploadList.forEach((item,indx)=>{
      item.uploadState = 3; // 设置为上传失败 
      newUploadList.push(item);
    });

    this.localUploadList = newUploadList;

    // 并保存到本地缓存 
    appAdapter().getStore().setLocalUploadList(newUploadList);
    
  }
  
  setupUploadCallbck(callback) {
     this.uploadCallbck = callback;
  }

  pushUploadObject(upload) {

    if (window.uploads == undefined) {
      window.uploads = [];
    }
    //  判断是否有上传的对象
    let isExist = false;
    for (let index = 0; index < window.uploads.length; index++) {
      let uploadObj = window.uploads[index];
      if (uploadObj.uid == upload.uid) {
        isExist = true;
        break;
      }
    }

    // 如果上传对象不存在，则就把当前对象加入到队列中 如果存在 则需要更新当前的对象
    if (!isExist && upload.originFileObj != undefined) {

      if (upload.uploadState == undefined) {
        upload.uploadState = 0;
        upload.uploadProcess = 0;
      }

      window.uploads.push(upload);

      console.log('上传列表保存到本地');
      // 存储到本地
     let _localUploadList = this.getLocalList();

      isExist = false;
      for (let index =0; index < _localUploadList.length; index ++) {
        let localupload = _localUploadList[index];
        if (localupload.uid == upload.uid) {
          isExist = true;
        }
      }

      if (isExist) {
        console.log('本地已经存在');
        return ;
      }
     
      let _localUplaod = {
        uploadState : upload.uploadState,
        uploadProcess : upload.uploadProcess,
        uploadData : upload.uploadData,
        uploadMeta : upload.uploadMeta,
        uid : upload.uid,
        title : upload.title,
        coverId: upload.coverId
      }
  

      _localUploadList.push(_localUplaod);
      appAdapter().getStore().setLocalUploadList(_localUploadList);

    }
    else {

      this.saveUploadObject(upload);
    }
  

  

  }

  publishVideo(file, data, hash) {
    let upload = {originFileObj : file, uploadMeta : data};
    upload.uid = hash;
    upload.uploadState = 0;
    upload.title = data.movName;

    // 获取文件的信息，这里主要是获取到视频的时间， 使用了一个叫做
    // https://unpkg.com/mediainfo.js/dist/mediainfo.min.js 获取时间 
    this.getFileSize(window.mediaInfoHandler,file).then(res=>{

      console.log('获取文件信息',res);
      let fileObject = JSON.parse(res);
      upload.mins = fileObject.media.track[0].Duration;
      console.log(fileObject);

      // 准备好了 则加入到上传队列中 
      this.pushUploadObject(upload);

    }).catch(err=>{
      console.log('获取文件出错');
    });

    // getFileSize(window.mediaInfoHandler,info.file.originFileObj)

  
  }

  updateVideo(upload,data) {
    upload.uploadMeta = data;
    upload.title = data.movName;
    upload.coverId = data.coverId
    this.pushUploadObject(upload);
  }
  
  resetUplod() {
    this.currentUploadState = 0;
  }


  

  saveUploadObject(upload) {

       //  判断是否有上传的对象
       let isExist = false;
       let currentObj = undefined;
       let currentIndex = -1;
       for (let index = 0; index < window.uploads.length; index++) {
         let uploadObj = window.uploads[index];
         if (uploadObj.uid == upload.uid) {
           currentIndex = index;
           currentObj = uploadObj;
          
           break;
         }
       }

       if (currentIndex != -1 && currentObj !=undefined) {
        window.uploads[currentIndex].uploadState = upload.uploadState;
        window.uploads[currentIndex].uploadProcess = upload.uploadProcess;
        window.uploads[currentIndex].uploadMeta = upload.uploadMeta;
        window.uploads[currentIndex].title = upload.title;
        window.uploads[currentIndex].coverId = upload.coverId
       }

      
       // 保存到本地的列表
       let _localUploadList = this.getLocalList();
       let _tmpIndex = -1;
       for (let index =0; index < _localUploadList.length; index ++) {

        let _tmp = _localUploadList[index];
        if (_tmp.uid == upload.uid) {
          _tmpIndex = index;
          break;
        }

       }


       if (_tmpIndex != -1) {

        _localUploadList[_tmpIndex].uploadState = upload.uploadState;
        _localUploadList[_tmpIndex].uploadProcess = upload.uploadProcess;
        _localUploadList[_tmpIndex].uploadMeta = upload.uploadMeta;
        _localUploadList[_tmpIndex].title = upload.title;
        _localUploadList[_tmpIndex].coverId = upload.coverId;
       }
       appAdapter().getStore().setLocalUploadList(_localUploadList);
  }

  removeUploadObject(uid) {

    let uploadList = this.getUplodingList();
    console.log('uploadList',uploadList);
    let delIndex  = -1;
    for (let index = 0; index < uploadList.length ; index  ++) {

      let upload = uploadList[index];
      if (upload.uid == uid) {
        delIndex = index;
      }
    }

    //  删除正在上传的状态
    if (delIndex != -1) {
      uploadList = uploadList.splice(delIndex,1);
      window.uploadList = uploadList;
    }

    delIndex = -1;

    let localUploadList = this.getLocalList();

    for (let index =0; index < localUploadList.length; index ++) {
      let upload = localUploadList[index];
      if (uid == upload.uid) {
        delIndex = index;
        break;
      }
    }

    if (delIndex != -1) {
      localUploadList = localUploadList.splice(delIndex, 1);
      appAdapter().getStore().setLocalUploadList(localUploadList);
      this.loadUploadingList();
    }
  }


 
  hasInArray(uid, datas) {
       
    let existIndex = -1;
    for (let index = 0; index < datas.length ; index  ++) {

      let upload = datas[index];
      if (upload.uid == uid) {
        existIndex = index; 
        break;
      }
    }

    return existIndex;

  }

  stopUpload(uid) {

    if (uid == undefined) {
      return ;
    }


    let uploadList = this.getUplodingList();
    let currentUpload = undefined;

    for (let index =0; index < uploadList.length; index ++) {
       let upload = uploadList[index];
       if (upload.uid == uid) {
         currentUpload = upload;
         break;
       }
    }


    if (this.uploadRequests[uid] !=undefined) {
      let uploadNode = this.uploadRequests[uid];
      uploadNode.abort();
    }

    setTimeout(() => {
      if (currentUpload) {
        currentUpload.uploadState = 5;
        currentUpload.uploadProcess = 0;
        this.saveUploadObject(currentUpload);
      }
    }, 500);

  }

  removeUploadLists(uids) {

    let uploadList = this.getUplodingList();
    let delIndexs  = [];
    console.log(uids)
    if (uids == undefined || uids.length <=0) {
      return ;
    }

    if (uploadList != undefined && uploadList.length >0) {

      for (let index = 0; index < uids.length ; index  ++) {
        let uid = uids[index];
        let existIndex = this.hasInArray(uid, uploadList);
        if (existIndex != -1) {
          delIndexs.push(existIndex);
        }
      }
    }

    delIndexs.forEach((item)=>{


      let uploadObj = uploadList[item];
    if (this.uploadRequests[uploadObj.uid] !=undefined) {
      let uploadNode = this.uploadRequests[uploadObj.uid];
      uploadNode.abort();
    }
    uploadList.splice(item,1);
    });

    if (delIndexs.length >0) {
      window.uploadList = uploadList;
    }


    delIndexs = [];

    let localUploadList = this.getLocalList();
    for (let index = 0; index < uids.length ; index  ++) {

      let uid = uids[index];
      let existIndex = this.hasInArray(uid, localUploadList);
      if (existIndex != -1) {
        delIndexs.push(existIndex);
      }
    }

    delIndexs.forEach((item)=>{
      localUploadList.splice(item, 1);
    });

    if (delIndexs.length >0) {
      appAdapter().getStore().setLocalUploadList(localUploadList);
      this.loadUploadingList();
    }
    
  }

  getUpload() {

    if (window.uploads == undefined) {
      window.uploads = [];
    }

    return window.uploads;
  }


  uploadFile() {

    // 判断当前是否在上传 
    if (this.currentUploadState == 1) {
      console.log('队列里面真正上传',window.uploads);
      console.log('uploadRequests',this.uploadRequests);
      return;
    }

    let uploads = this.getUpload();
    if (uploads.length == 0) {
      // console.log('没有需要上传的数据咯');
      return ;
    }

    for (let index =uploads.length -1; index >=0; index --) {

      let uploadObj = uploads[index];
      if (uploadObj.uploadState == 0 || uploadObj.uploadState == 1) {

        // 获取到满足上传 则就进入上传流程 这个过程不需要阻塞当前的流程 
        this.__uploadFile(uploadObj);
        break;
      }
    }
  }



  getFileSize(mediainfo, file) {
    let getSize = () => file.size
    let readChunk = (chunkSize, offset) =>
      new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = (event) => {
          if (event.target.error) {
            reject(event.target.error)
          }
          resolve(new Uint8Array(event.target.result))
        }
        reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize))
      })
  
    return mediainfo.analyzeData(getSize, readChunk)
}

  
  calculateMD5Hash(file, bufferSize) {
    return new Promise((resolve, reject) => {

      console.log('读取文件',file);
      let fileReader = new FileReader();
      let fileSlicer = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
      let hashAlgorithm = new SparkMD5();
      fileReader.onload = function (e) {
          let buffer = e.target.result;
          hashAlgorithm.appendBinary(buffer);
          let md5 = hashAlgorithm.end()
          
          if (resolve) {
            resolve(md5);
          }
      };
  
      fileReader.onerror = function (e) {
        console.log('error',e);
        if (reject) {
          reject(e);
        }
      };

      console.log('file',file);
      fileReader.readAsBinaryString(fileSlicer.call(file, 0, bufferSize));
    });
  }


  hasUplaodObject(hash) {

    let uploads = this.getUpload();
    let bRet = false;
    for (let index =0; index <uploads.length; index ++) {
      let obj = uploads[index];
      if (obj.uid == hash) {
        bRet = true;
        break;
      }
    
    }
    return bRet;

  }


  getLocalList() {
    return this.localUploadList;
  }

  getUplodingList() {
    return window.uploads;
  }
  
  
  __uploadFile(upload) {

    let file = upload.originFileObj;
    console.log('开始准备上传文件', upload);
    this.currentUploadObject = upload;
    this.saveUploadObject(this.currentUploadObject);

    
    this.currentUploadObject.uploadState = 1; 
    this.currentUploadState = 1;
    let _this = this;

    // 获取到当前的上传参数 
    this.upload.getUploadParams().then(res => {
      window.accessKey = res.data[0].accessKey;
      window.secretKey = res.data[0].secretKey;
      window.token = res.data[0].token;
      window.userId = res.data[0].userId;
      let fileName = file.name;
      let timestamp = new Date().getTime();
      let md5 = hexMD5(fileName + timestamp)
      let movieUrl =  `h5/${md5}.mp4`

      // 构建aws 所需要的参数 
      AWS.config.update({
        accessKeyId: window.accessKey ,
        secretAccessKey: window.secretKey,
        endpoint: window.endpoint,
        Region: 'us-east-1',
        s3ForcePathStyle: true
      });
      var uploadParams = {
        Bucket: window.bucketName,
        Key: movieUrl,
        ACL: 'public-read',
        Body: file,
        ContentType: 'video/mp4'
      };


      // 构建上传分片信息 
      let options = {
        partSize: 5 * 1024 * 1024,
        queueSize: 1,
        params:uploadParams,
        leavePartsOnError: false //失败重传
      };

      // 进入上传流程
      let uploadNode = new AWS.S3.ManagedUpload(options);
      console.log('uploadNode',uploadNode);
      let uploadRequests = _this.uploadRequests;
      uploadRequests[upload.uid] = uploadNode;
      _this.uploadRequests = uploadRequests;

      uploadNode.send(function(err, data) {
        _this.currentUploadState = 0;
     
        if (err == undefined) {

          _this.currentUploadObject.uploadState = 4; 
          _this.currentUploadObject.uploadData = data;
          let movieData = _this.currentUploadObject.uploadMeta;
              //竖图，有则传，没有可不传
          //封面图地址

        
          // 成功后的地址，这个和服务提供方 进行约定 // 

          // 安卓封面eg：  /cukefree/android/202e54682d0e0b7a288b549ffb40388a.jpg
          let horizontalLarge = '', verticalLarge = '';
          if(movieData.coverId.minioUrl){ // 用户自己传封面
            let coverId = movieData.coverId
            if(coverId.width < coverId.height){  // 通过长宽判断横竖图
              verticalLarge = `/${window.bucketName}/${coverId.minioUrl}`
            } else {
              horizontalLarge = `/${window.bucketName}/${coverId.minioUrl}`
            }
            console.log('uploadMeta===>', horizontalLarge)

          }else { // 泰坦截取1000 帧封面
            horizontalLarge = `/${window.bucketName}/${movieUrl}/thumb-1000.jpg`;
            verticalLarge = `/${window.bucketName}/${movieUrl}/thumb-1000.jpg`;
          }
          console.log('uploadMeta....>', horizontalLarge)

          let videoUrl = `/${window.bucketName}/${movieUrl}`

          let uid = _this.currentUploadObject.uid;
          let mins = parseInt(_this.currentUploadObject.mins)/60;
          mins = parseInt(mins.toString());
          
          console.log('11---movieData',movieData);

          // 提交视频上传后的信息 
          let movObj = {}
          let resourceType = 1
          console.log('33333=====mins',mins<= 5);
          if(mins<= 5) {
            resourceType = 3
          }
          
          if (movieData.movPackageId == undefined) {
            movObj = {
              movName : movieData.movName,
              hash : uid,
              movDesc:movieData.movDesc,
              localId : movieData.localId,
              tagids : movieData.tagids,
              shard : videoUrl,
              horizontalLarge: horizontalLarge,
              verticalLarge: verticalLarge,
              movCls :  movieData.movCls,
              mins : mins,
              resourceType: resourceType
            };
          }

          else {
            movObj = {
              movName : movieData.movName,
              hash : uid,
              movDesc:movieData.movDesc,
              localId : movieData.localId,
              movPackageId:movieData.movPackageId,
              tagids : movieData.tagids,
              shard : videoUrl,
              horizontalLarge:horizontalLarge,
              verticalLarge:verticalLarge,
              movCls :  movieData.movCls,
              mins : mins,
              resourceType: resourceType
            };
          }


          _this.upload.publishMovie(movObj).then(res=>{
               console.log('保存视频信息',res);
               _this.removeUploadLists([_this.currentUploadObject.uid]);
          }).catch(err=>{
            console.log('保存视频信息失败',err);
          });

          console.log('上传成功', data, err);
        
        } else {
          _this.currentUploadObject.uploadState = 3; 
          _this.currentUploadObject.uploadData = err;
          console.log('上传失败===》', data, err);
        }
      });
      uploadNode.on('httpUploadProgress', function(evt) {

        // 进度条处理 
        let precent = 0;
        if (evt.loaded > 0 || evt.total > 0) {
          precent = evt.loaded / evt.total;
        }
        console.log('上传进度', precent);
        _this.currentUploadObject.uploadProcess = precent;
        _this.saveUploadObject(_this.currentUploadObject);
        if (_this.uploadCallbck) {
      
          _this.uploadCallbck(null, _this.currentUploadObject);
        }
      });

      console.log('uploadNode',uploadNode);



    }).catch(err => {

      // 失败的处理 
      _this.currentUploadState = 0;
      _this.saveUploadObject(_this.currentUploadObject);
      if (_this.uploadCallbck) {
        
        _this.uploadCallbck(null, _this.currentUploadObject);
      } 

    });

  }


}

export function GetUploadManager() {

  if (window.uploadManager == undefined) {
    window.uploadManager = new CPUploadManager();
    window.hasInstaceUploadManager = true;
  }
  return window.uploadManager;
}