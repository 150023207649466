import {setStorageSync, getStorageSync, removeStorageSync, setLocalStorage, getLocalStorage, removeLocalStorage} from './appstorage'

let CP_WEAPP_AUTHORIZATION  = 'weapp_authorization'
let CP_WAPP_USER = 'wapp_user'
let CP_SEQ = 'wapp_Seq'
let CP_LOCALUPLOADLIST = 'wapp_localuploadList'
export default class CPStorage {
    storeAuthorization(auth) {
        setStorageSync(CP_WEAPP_AUTHORIZATION, auth)
    }
    getAuthorization() {
        return getStorageSync(CP_WEAPP_AUTHORIZATION)
    }
    storeWappUser(user) {
        setStorageSync(CP_WAPP_USER, user)
    }
    getWappUser() {
        return getStorageSync(CP_WAPP_USER)
    }
    removeWappUser() {
        removeStorageSync(CP_WAPP_USER)
    }
    setSeq(item) {
        setStorageSync(CP_SEQ, item)
    }
    getSeq() {
        return getStorageSync(CP_SEQ)
    }
    set(auth) {
        setLocalStorage('key', auth)
    }
    get() {
        return getLocalStorage('key')
    }


    setLocalUploadList(data) {
        setLocalStorage(CP_LOCALUPLOADLIST, data);
    }

    getLocalUploadList() {
        return getLocalStorage(CP_LOCALUPLOADLIST);
    }
    // remove() {
    //     removeLocalStorage('key')
    // }

}